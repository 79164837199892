.page-container .page-list .complex-cell,
.section-list .list-group-item {
  border-color: #D7D7D7;
  border-style: solid;
  border-width: 1px 0;
  margin-top: -1px;
}

.page-container .control-pane.both .card-body {
  text-align: center;
}

.page-container .control-pane.both span.or {
  padding: 0 15px;
}

.page-container .control-pane.both span.to {
  display: none;
}

.page-container .page-list:empty {
  display: none;
}

.page-container .page-list .control-pane.both span.to {
  display: block;
  margin-top: 15px;
}

.page-container .control-pane div.btn-group.closer {
  float: right;
  margin: 20px 10px 0 0;
}

.page-container div.btn-group.closer button.close {
  opacity: 0.5;
}

.page-container div.btn-group.closer button.close:hover {
  opacity: 1;
}

.page-container .page-list .page-container.card {
  margin-top: 8px;
  margin-left: 20px;
  margin-right: 20px;
}

.page-container .page-list .page-container .control-pane.section .field-group {
  padding-top: 0;
}

.audit-label {
  font-size: 16px;
}

.audit-table .results td {
  padding: 6px;
  font-size: 14px;
}

.audit-table th {
  padding: 6px;
  font-size: 15px;
}

.audit-table .destination {
  text-transform: capitalize;
}

.audit-table .icon-col {
  text-align: center;
}

.audit-table .err-col {
  white-space: pre-wrap;
}

.audit-table .results tr:nth-child(even) {
  background-color: #FFF1EB;
}

.audit-table .results {
  background-color: #FFFFFF;
}
