.menu-cover-preview {
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  font-family: HelveticaNeue-CondensedBold, sans-serif;
  line-height: 1.2;
  position: relative;
}

.menu-cover-preview .image-container {
  background-color: black;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.menu-cover-preview .decline-button-container {
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: 1vh;
}

.menu-cover-preview .decline-button-container .decline-button {
  color: #FFFFFFFF;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  text-shadow: 0 2px 8px #000000;
  width: 380px;
  margin: 0 auto;
  height: 100%;
}

.menu-cover-preview .terms-of-use {
  color: #FFFFFFFF;
  bottom: 0.5vh;
  position: absolute;
  right: 8px;
  font-weight: bold;
  font-size: 14px;
  text-shadow: 0 2px 8px #000000;
}
