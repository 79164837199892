@import "https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,400;0,600;0,700;0,900;1,400;1,600&display=swap";
@import "https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;600&display=swap";

:root {
  --font-primary: "Public Sans", helvetica, arial, sans-serif;
  --bs-font-monospace: "Fira Code", menlo, monospace;
}

.multi-view > div:nth-child(n+2) {
  margin-top: 20px;
}

.base-list-view {
  position: relative;
}

.base-list-view > .card,
.anchored-modal > .card {
  margin-bottom: 0;
}

.base-list-view .empty-pane,
.accordion .card .empty-pane {
  text-align: center;
  padding: 30px;
  font-size: 16px;
  color: darkgray;
}

.base-list-view .loading-pane {
  padding: 45px;
  background-image: url("https://static.bureau.getbite.com/images/spinner-green-on-white.gif");
  background-repeat: no-repeat;
  background-position: center;
}

.list-container.list-group {
  overflow-y: scroll;
}

.list-container.list-group.vlist {
  display: block;
}

.list-container.list-group > .div {
  overflow: scroll;
}

.list-container .list-separator {
  padding: 8px;
  text-align: center;
  font-family: var(--font-primary);
  font-weight: 600;
  background-color: #EEEEEE;
}

.list-container .list-separator > .right-button-container {
  float: right;
  margin-top: -5px;
}

.list-container .list-separator > a {
  text-decoration: none;
}

.list-container .list-separator a:hover {
  color: #FF7401;
}

.list-container .load-more {
  height: 44px;
}

.list-container .load-more .load-more-button {
  padding: 12px;
  color: #337AB7;
  cursor: pointer;
  line-height: 24px;
  font-size: 18px;
  text-align: center;
  text-decoration: underline;
}

.list-container .load-more .load-more-button:hover {
  background-color: #F5F5F5;
  color: #FF7401;
}

.card-header + .list-group .list-group-item:first-child {
  border-top-width: 1px;
}

.list-container .fetching {
  padding: 12px;
  display: none;
  height: 100%;
  width: 100%;
  background-image: url("https://static.bureau.getbite.com/images/spinner-green-on-white.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;
}

@media (max-width: 1024px) {
  .form-group.card {
    font-size: 16px;
    line-height: 1.5;
  }
}

.form-group.card .search-field,
.form-group.card .filter-container,
.form-group.card .new-panel {
  border-bottom: none;
}

.form-group.card .search-field + .list-container .list-group-item:first-child,
.form-group.card .new-panel + .list-container .list-group-item:first-child {
  border-top-color: white;
}

.form-group.card .filter-container {
  display: flex;
  align-items: center;
}

.form-group.card .filter-container .field-group {
  flex-grow: 1;
}

.form-group.card .filter-container .button-container {
  flex-grow: 1;
}

/*
 * Allow list cell contents to span the entire cell. This means that the cell
 * might need to be padded in cases where the contents do not provide enough
 * vertical filling.
 */
.list-group-item {
  padding: 0;

  /* display: table; */
  width: 100%;
}

.touch .list-group-item:focus,
.touch .list-group-item:hover {
  color: inherit;
  text-decoration: none;
  background-color: inherit;
}

.list-group-item.clickable {
  cursor: pointer;
}

.list-group-item.clickable:hover,
.list-group-item.clickable:active {
  background-color: #F5F5F5;
}

.list-group-item .name {
  display: table-cell;
  width: 100%;
  padding: 10px;
}

.list-group-item .name.with-link {
  padding: 0;
}

.cell > .name span[data-bs-toggle="tooltip"] {
  text-decoration: underline;
}

pre {
  position: relative;
  white-space: pre-wrap;
}

pre[alt],
pre[warning] {
  padding-top: 24px;
}

pre[alt]::before,
pre[warning]::after {
  position: absolute;
  top: 0;
  padding: 2px 6px;
  border-bottom: 1px solid #CCCCCC;
  font-size: 10px;
  font-family: var(--font-primary);
  font-weight: 500;
  max-width: 49%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

pre[alt]::before {
  content: attr(alt) ":";
  left: 0;
  color: #666666;
  background-color: white;
  border-right: 1px solid #CCCCCC;
  text-align: left;
}

pre[warning]::after {
  content: attr(warning);
  right: 0;
  color: red;
  background-color: white;
  border-left: 1px solid #CCCCCC;
  font-weight: 600;
  text-align: right;
}

.cell > .cell-row > .name.with-link > a {
  display: block;
  color: inherit;
  text-decoration: none;
  padding: 10px;
}

.cell > .cell-row > .name.with-link > a .subtitle {
  display: block;
  font-size: 12px;
}

.cell > .cell-row > .name pre {
  margin: 10px 0 0;
}

.cell > .cell-row > .name .footer {
  color: #666666;
  font-size: 14px;
}

.cell > .cell-row > .name a[model-id],
.cell > .cell-row > .name span[model-id] {
  text-decoration: underline;
  cursor: pointer;
}

.cell > .cell-row > .accessory-label {
  display: table-cell;
  padding: 10px 10px 10px 0;
  font-size: 13px;
  white-space: nowrap;
}

.badge {
  --bs-badge-color: white;
  --bs-font-size: 14px;
  --bs-font-weight: bold;
  --bs-border-radius: 6px;

  background-color: #999999;
  margin: 6px;
}

.cell-badge {
  border-radius: 2px;
  color: white;
  display: inline-block;
  font-size: 11px;
  font-style: normal;
  height: 20px;
  margin-right: 4px;
  padding: 2px 4px;
  text-transform: uppercase;
  white-space: nowrap;
}

.cell-badge.added {
  background-color: #417505;
}

.cell-badge.deleted,
.cell-badge.error {
  background-color: #D0011B;
}

.cell-badge.updated,
.cell-badge.sync-summary,
.cell-badge.day-part-change {
  background-color: #9B9B9B;
}

.cell-badge.not-in-menu {
  background-color: #F6A623;
}

.cell-badge.variation {
  background-color: gray;
}

.cell-badge.unarchived {
  background-color: #33013C;
}

.cell-badge.archived {
  background-color: #5F166C;
}

.cell-badge.compound-item {
  background-color: violet;
}

.cell.quicksand-record > .cell-row > .btn-group.not-in-menu {
  text-align: right;
  padding-right: 8px;
}

.cell.quicksand-record > .cell-row > .btn-group.not-in-menu > .cell-badge {
  margin-bottom: 4px;
}

.cell.quicksand-record > .cell-row > .btn-group.not-in-menu > .btn-group {
  float: right;
}

.cell.quicksand-record > .cell-row > .btn-group-container > .btn-group > .btn.view-diff {
  width: 50px;
}

.cell.maitred-activity > .cell-row > .name a.activity-scope-name {
  display: block;
  font-size: 13px;
  color: black;
}

@media (max-width: 1024px) {
  .list-group-item .name {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

.list-group-item .cell-body .name {
  padding-left: 0;
}

.list-group-item.cell.selected .name {
  color: #FF7401;
}

.list-separator .badge-img,
.list-group-item .name .badge-img,
ul.dropdown-menu li a .badge-img,
.cell-body .badge-img {
  width: 20px;
  height: 20px;
}

ul.dropdown-menu li a .badge-img:first-child {
  margin-right: 5px;
}

.list-separator .order-channel-tag,
.list-group-item .name .order-channel-tag,
ul.dropdown-menu li a .order-channel-tag,
.cell-body .order-channel-tag {
  display: inline-block;
  height: 20px;
  border-radius: 2px;
  padding: 2px 4px;
  border: 1px solid #999999;
  text-align: center;
  color: #999999;
  line-height: 1.3;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  vertical-align: bottom;
}

.list-separator .order-channel-tag.live,
.list-group-item .name .order-channel-tag.live,
ul.dropdown-menu li a .order-channel-tag.live,
.cell-body .order-channel-tag.live {
  font-weight: bold;
}

.list-separator .order-channel-tag.live.kiosk,
.list-group-item .name .order-channel-tag.live.kiosk,
ul.dropdown-menu li a .order-channel-tag.live.kiosk,
.cell-body .order-channel-tag.live.kiosk {
  border-color: #FF7401;
  color: #FF7401;
}

.list-separator .order-channel-tag.live.web,
.list-group-item .name .order-channel-tag.live.web,
ul.dropdown-menu li a .order-channel-tag.live.web,
.cell-body .order-channel-tag.live.web {
  border-color: #0D2481;
  color: #0D2481;
}

.list-separator .order-channel-tag.live.catering,
.list-group-item .name .order-channel-tag.live.catering,
ul.dropdown-menu li a .order-channel-tag.live.catering,
.cell-body .order-channel-tag.live.catering {
  border-color: #33B0D3;
  color: #33B0D3;
}

.list-separator .order-channel-tag.live.contactless,
.list-group-item .name .order-channel-tag.live.contactless,
ul.dropdown-menu li a .order-channel-tag.live.contactless,
.cell-body .order-channel-tag.live.contactless {
  border-color: #27AA5E;
  color: #27AA5E;
}

.list-separator .order-channel-tag.live.linebuster,
.list-group-item .name .order-channel-tag.live.linebuster,
ul.dropdown-menu li a .order-channel-tag.live.linebuster,
.cell-body .order-channel-tag.live.linebuster {
  border-color: #5e27aa;
  color: #5e27aa;
}

.list-separator .order-channel-tag.live.drive-thru,
.list-group-item .name .order-channel-tag.live.drive-thru,
ul.dropdown-menu li a .order-channel-tag.live.drive-thru,
.cell-body .order-channel-tag.live.drive-thru {
  border-color: #d80075;
  color: #d80075;
}

.list-group-item .status-label {
  display: table-cell;
  font-weight: 700;
  margin: 10px 0 0 10px;
  padding-right: 14px;
}

.list-group-item .status-label.paid {
  color: green;
}

.list-group-item .status-label.refunded {
  color: orange;
}

.list-group-item .name .description {
  display: block;
  font-size: 13px;
  margin-top: 2px;
}

.list-group-item .name .description .subtext {
  color: #666666;
}

.card .card-header .title.unsaved-changes::after,
.modal-header .modal-title.unsaved-changes::after {
  content: " (Unsaved Changes)";
}

.card .card-header {
  display: table;
  width: 100%;
  border-bottom: none;
}

.card .card-header .title {
  display: table-cell;
  vertical-align: middle;
}

.card .card-header .title .subtitle {
  display: block;
  font-size: 85%;
  margin-top: 2px;
}

.card .card-header .title .subtitle.warning {
  color: #FF7401;
}


.card .card-header .right-button-container {
  display: table-cell;
  vertical-align: middle;
  margin-top: -4px;
  text-align: right;
}

.card .card-header .right-button-container .btn-primary {
  letter-spacing: 0.5px;
  display: inline-flex;
  line-height: 1.6;
}

/* Bootstrap icon adjustments */
.card .card-header .right-button-container .btn-primary .bi {
  margin-right: 5px;
  font-size: 1.5rem;
  display: inline-flex;
  align-items: center;
}

.base-list-view .card .card-header .right-button-container .info {
  margin-top: 7px;
  font-size: 12px;
}

.card .card-header .right-button-container > .card-body {
  padding: 0;
  margin-top: -3px;
}

.card > .search-field .form-group {
  margin: 15px;
  position: relative;
}

.card > .search-field .form-group button,
.card > .search-field .form-group img.spinner {
  position: absolute;
  top: 0;
  right: 0;
  height: 34px;
}

.card > .search-field .form-group img.spinner {
  padding: 4px 8px;
}

.card > .search-field .form-group .search-hint {
  color: #666666;
  font-size: 13px;
  margin-top: 10px;
  display: none;
}

.card > .search-field .form-group .search-hint span {
  cursor: pointer;
}

.card > .search-field .form-group .search-hint span:hover {
  text-decoration: underline;
}

.dupe-items {
  padding: 5px 10px 10px;
}

.dupe-item {
  display: table;
  padding-top: 10px;
}

.dupe-item .img {
  display: table-cell;
  width: 120px;
  height: 80px;
  border: 1px solid #B7B7B7;
  margin-right: 10px;
  background-image: url("https://static.bureau.getbite.com/images/no-photo@2x.png");
  background-repeat: no-repeat;
  background-position: center;
}

.dupe-item .text {
  display: table-cell;
  padding-left: 10px;
}

.dupe-item .text .badge-img {
  width: 20px;
  height: 20px;
}

.dupe-item .text .name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

.modal-footer .confirmation {
  float: left;
  font-size: 16px;
  padding-top: 8px;
  text-align: left;
  margin-right: 16px;
  font-style: italic;
}

@media (max-width: 560px) {
  .modal-footer .confirmation {
    padding: 0 0 12px;
  }
}

.list-header .container {
  border-bottom: rgb(221 221 221) solid 1px;
  padding: 15px;
  width: 100%;
}

.list-header .container .error {
  color: red;
  margin-bottom: 10px;
}

.list-header .container .error:empty {
  display: none;
}

.list-header .container .connected {
  color: green;
  font-size: 16px;
}

.list-header .container .list-dropdown.fetching {
  background-image: url("https://static.bureau.getbite.com/images/spinner-green-on-white.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  height: 30px;
}

.list-header .custom-report-pane {
  display: table;
  padding-bottom: 10px;
  padding-top: 5px;
}

.list-header .custom-report-pane .field-group {
  display: table-cell;
  width: 100%;
}

.list-header .custom-report-pane .field-group .col-form-label {
  width: 100%;
  float: none;
}

.list-header .custom-report-pane .button-container {
  display: table-cell;
  vertical-align: bottom;
  padding: 15px 0;
}
