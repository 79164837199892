/*
 * KIOSK PREVIEW
 */
.field-container.kiosk-preview-view .test-url {
  margin-bottom: 20px;
}

.field-container.kiosk-preview-view .disclaimer {
  color: #555555;
}

.field-container.kiosk-preview-view .disclaimer li {
  font-size: 14px;
}

.preview-warning {
  color: darkred;
  font-weight: initial;
  font-size: 12px;
  display: table-cell;
  vertical-align: middle;
  margin-bottom: 10px;
}

/*
 * LOCATION/INTEGRATION SETTINGS
 */
.tab-panel > .tools-view .toolbar,
.integration-sync-panel {
  margin: 20px 0;
  padding: 12px;
}

.tab-panel > .tools-view .toolbar button {
  margin: 4px;
}

.integration-sync-panel .synced-at,
.integration-sync-panel .sync-summary {
  margin-top: 5px;
  display: block;
}

.integration-sync-panel ul {
  list-style: none;
  -webkit-padding-start: 0;
}

.integration-sync-panel li {
  padding-left: 8px;
}

.integration-sync-panel {
  max-height: 150px;
  overflow-y: scroll;
}

.list-header .integration-toolbar {
  display: table;
}

/*
 * KIOSK location-settings
 */
.list-header .kiosk-toolbar {
  display: table;
}

.list-header .kiosk-toolbar > div {
  display: table-cell;
  vertical-align: middle;
}

.list-header .kiosk-toolbar .change-all-kiosks {
  text-align: right;
  width: 100%;
  display: inline-block;
  margin-left: 4px;
}

/*
 * SEARCH VIEW
 */
.search-view .search-field .input-container {
  position: relative;
}

.search-view .search-field .input-container .form-control-feedback.fetching {
  background-image: url("https://static.bureau.getbite.com/images/spinner-green-on-white.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 65%;
}

.search-view .search-results div.result {
  padding: 10px;
}

.search-view .search-results div.result.error {
  color: red;
}
