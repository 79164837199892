.modal-open .modal {
  overflow-y: hidden;
}

/* .modal-dialog {
  overflow-y: initial !important;
} */

.anchored-modal > .card > .card-body,
.modal-body {
  overflow-y: auto;
}

.card-header .close {
  margin-top: -2px;
  background-color: rgb(231 76 60 / 0.1);
  border: none;
  border-radius: 6px;
  color: #E74C3C;
  display: table-cell;
  font-size: 24px;
  height: 38px;
  width: 38px;
}

.footer-view {
  text-align: right;
}

.footer-view .confirm-panel .left-container,
.footer-view .default-panel .left-container {
  text-align: left;
  float: left;
  line-height: 1.25em;
}

.footer-view button + button,
.footer-view button + div.dropdown-button-container,
.footer-view div.dropdown-button-container + button {
  margin-left: 8px;
  margin-right: 0;
}

.footer-view div.dropdown-button-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-top: 0;
}

.footer-view .confirm-panel .left-container button,
.footer-view .default-panel .left-container button {
  float: left;
  margin-left: 0;
  margin-right: 8px;
}

.footer-view .confirm-panel .left-container span.footer-label,
.footer-view .default-panel .left-container span.footer-label {
  display: inline-block;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .footer-view .default-panel button {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.footer-view button.btn.saved {
  background-color: #5DA50A;
}
