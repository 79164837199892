.list-group .list-group-item.integration-placeholder-cell-view .cell-row {
  display: block;
}

.list-group .list-group-item.integration-placeholder-cell-view .cell-row .cell-body {
  display: block;
  width: initial;
  margin: 7px;
  padding: 20px;
  border: 4px dashed #999999;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
