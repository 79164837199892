.json-view-content {
  color: #000000;
  padding-left: 20px;
}

.json-view-content ul {
  list-style-type: none;
  padding-left: 0;
}

.json-view-content ul .indent {
  border-left: 1px dotted #CCCCCC;
}

.json-view-content .hide {
  display: none;
}

.json-view-content .type-string {
  color: #0B7500;
}

.json-view-content .type-date {
  color: #CB7500;
}

.json-view-content .type-boolean {
  color: #1A01CC;
  font-weight: bold;
}

.json-view-content .type-number {
  color: #1A01CC;
}

.json-view-content .type-timestamp {
  color: #1A01CC;
}

.json-view-content .type-number .human-readable-string,
.json-view-content .type-timestamp .human-readable-string {
  color: #AAAAAA;
}

.json-view-content .type-null,
.json-view-content .type-undefined {
  color: #9900AA;
}

.json-view-content span.list-link {
  color: #000000;
  position: relative;
  cursor: pointer;
}

.json-view-content span.list-link::before {
  color: #AAAAAA;
  content: "\25BC";
  position: absolute;
  display: inline-block;
  width: 1em;
  left: -0.75em;
}

.json-view-content span.list-link.collapsed::before {
  content: "\25B6";
}

.json-view-content span.list-link.empty::before {
  content: "";
}

.json-view-content .items-ph {
  color: #AAAAAA;
  padding: 0 1em;
}

.json-view-content .items-ph:hover {
  text-decoration: underline;
}
