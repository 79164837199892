.build-cell-view {
  padding: 12px 12px 7px;
}

.build-cell-view .name-timestamp {
  display: flex;
  padding-bottom: 10px;
}

.build-cell-view .name-timestamp .name {
  width: auto;
  flex: 1;
  padding: 0;
  font-weight: 500;
}

.build-cell-view .name-timestamp .timestamp {
  text-align: right;
  color: #9B9B9B;
  font-size: 13px;
  min-width: 64px;
}

.build-cell-view .availability {
  padding-bottom: 10px;
}

.build-cell-view .release-notes-container,
.build-cell-view .release-history-container {
  color: #9E9E9E;
  font-size: 13px;
  padding-bottom: 7px;
}

.build-cell-view .release-notes-container .toggle .title,
.build-cell-view .release-history-container .toggle .title {
  margin-right: 3px;
  text-decoration: underline;
  cursor: pointer;
}

.build-cell-view .release-notes-container .toggle .arrow,
.build-cell-view .release-history-container .toggle .arrow {
  display: inline-block;
  font-size: 8px;
  transition: all 0.3s ease;
}

.build-cell-view .button-container {
  /* Clearfix so that container does not collapse if only the Sentry button exists */
  overflow: auto;
}

.build-cell-view .button-container button {
  margin-right: 5px;
  margin-bottom: 5px;
}

.build-cell-view .button-container button.sentry {
  float: right;
  margin: 0 0 5px 5px;
}

.build-cell-view .button-container button.sentry img {
  margin-right: 3px;
  height: 15px;
  vertical-align: text-bottom;
}
