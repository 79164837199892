@import "https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,400;0,600;0,700;0,900;1,400;1,600&display=swap";

:root {
  --font-primary: "Public Sans", helvetica, arial, sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
}

html {
  width: 100%;
  height: 100%;
  text-size-adjust: 100%;
}

body {
  width: 100%;
  overflow-x: hidden;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* desktop only */
  position: relative;
  min-height: 100%;
  height: 100%;
  overflow-y: hidden;
}

/* Mobile base font size. */
@media (max-width: 768px) {
  body {
    font-size: 16px;
    line-height: 1.5;
  }
}

@media (max-width: 1024px) {
  body {
    overflow: initial;
  }
}

body.login-view {
  overflow: hidden;
}

#app-view {
  font-family: "Public Sans", Helvetica, Arial, sans-serif;
  margin-top: 58px;
  position: absolute;
  z-index: 1;
  inset: 0;
  overflow: hidden;
}

body.login-view #app-view {
  margin-top: 0;
}

#app-view div.component-tabs {
  height: 100%;
  margin-left: 200px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

#app-view div.component-tab {
  margin-top: 20px;
  margin-bottom: 20px;
}

#app-view div.component-tab .form-group.card {
  margin-bottom: 10px;
}

#app-view div.component-tab .edit-panel {
  padding: 10px;
}

@media (min-width: 1025px) {
  #app-view div.component-tab {
    display: flex;
  }

  #app-view div.component-tab.single-list-tab {
    justify-content: flex-end;
  }

  #app-view div.component-tab .tab-panel.left-panel {
    padding-right: 6px;
  }

  #app-view div.component-tab .tab-panel.right-panel {
    padding-left: 6px;
  }

  #app-view div.component-tab .tab-panel.left-panel.full-width {
    padding-left: 6px;
    width: 100%;
  }

  #app-view div.component-tab .tab-panel.left-panel.full-width .iframe-view iframe {
    width: 100%;
    height: 100%;
  }

  #app-view div.component-tab.single-list-tab .tab-panel.left-panel {
    padding-left: 0;
    padding-right: 0;
    width: 35%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    overflow-y: hidden;
  }

  #app-view div.component-tab.single-list-tab .tab-panel.right-panel {
    float: right;
    width: 64%;
  }

  #app-view div.component-tab.single-list-tab .tab-panel.right-panel:empty {
    border: 6px #B7B7B7 dashed;
    height: 100%;
    border-radius: 11px;
    margin: 0 15px;
    width: calc(64% - 30px);
    display: table;
    color: #B7B7B7;
    font-size: 1.5em;
    font-weight: 500;
    text-align: center;
  }

  #app-view div.component-tab.single-list-tab .tab-panel.right-panel:empty::after {
    content: "Select an item from the left to edit it";
    display: table-cell;
    vertical-align: middle;

    /* Seems impossible to enforce the width, so this is hacky way to do it */
    padding-left: calc(50% - 100px);
    padding-right: calc(50% - 100px);
  }

  #app-view div.component-tab.single-list-tab .tab-panel.left-panel .base-list-view .card {
    border-radius: 0;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;
  }

  #app-view div.component-tab.single-list-tab .tab-panel.left-panel .base-list-view .card .card-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

@media (max-width: 1024px) {
  #app-view {
    position: relative;
    overflow: initial;
    width: 100%;
    z-index: initial;
  }

  #app-view div.component-tabs {
    margin-left: 215px;
    overflow: auto;
  }

  #app-view div.component-tab .tab-panel {
    padding-left: 8px;
    padding-right: 8px;
  }

  #app-view div.component-tab .tab-panel + .tab-panel {
    margin-top: 20px;
  }

  #app-view div.component-tab .edit-panel {
    display: none;
  }

  #app-view div.component-tab .main-panel > .card {
    margin-bottom: 0;
  }

  button.btn {
    font-size: 16px;
  }

  button.btn-sm {
    font-size: 14px;
  }

  .btn.dropdown-toggle {
    font-size: 16px;
  }

  .dropdown-menu {
    font-size: 16px;
  }
}

.dropdown-menu.show {
  width: 100%;
  max-height: 185px;
  overflow-x: hidden;
  box-shadow: 0 6px 12px #0000002d;
}

.dropdown-menu.show .item,
.dropdown-menu .search {
  padding: 4px 12px;
  font-size: 14px;
}

.dropdown-menu.show .item:hover {
  background-color: #EAEAEA;
}

@media (max-width: 560px) {
  #app-view div.component-tabs {
    margin-left: 0;
  }

  #app-view div.component-tab {
    padding: 10px 0 0;
    border: none;
  }

  #app-view div.component-tab .tab-panel {
    padding-left: 0;
    padding-right: 0;
  }

  #app-view div.component-tab .tab-panel.right-panel:not(:empty) {
    padding-top: 20px;
  }

  #app-view div.component-tab .tab-panel > div > .form-group.card {
    margin-bottom: 0;
  }
}

.modal-content {
  font-family: var(--font-primary);
}

.modal-dialog {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (max-width: 560px) {
  .modal-dialog {
    margin: 0;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    --bs-modal-width: 660px;
  }
}

/* Override bootstrap defaults. Slide up the modal from the bottom. */

/* #modals .modal.fade .modal-dialog {
  transform: translate(0, 25%);
  transition:
    transform 0.15s ease-out,
    -webkit-transform 0.15s ease-out,
    -o-transform 0.15s ease-out;
}

#modals .modal.in .modal-dialog {
  transform: translate(0, 0);
} */

.modal-header {
  display: table;
  padding: 0;
}

.modal-header .modal-title {
  font-weight: bold;
  display: table-cell;
  padding-left: 15px;
  vertical-align: middle;
  width: 100%;
}

.modal-header .right-button-container {
  display: flex;
}

.modal-header .right-button-container button.btn-primary {
  margin: 10px;
}

.modal-header button.close {
  background-color: rgb(231 76 60 / 0.1);
  border: none;
  border-radius: 6px;
  color: #E74C3C;
  display: table-cell;
  font-size: 24px;
  margin: 10px;
  height: 38px;
  width: 38px;
}

.modal-header button:active {
  background-color: #DDDDDD;
}

#alert-modal {
  z-index: calc(var(--bs-modal-zindex) + 2);
}

.modal-backdrop + .modal-backdrop {
  /* 1055 is --bs-modal-zindex but it's not available to us here */
  z-index: calc(1055 + 1);
}

#mobile-bookmark-prompt {
  position: fixed;
  bottom: 0;
  left: 215px;
  right: 0;
  background-color: #F7F7F7;
  z-index: 1;
  box-shadow: 0 -4px 3px 1px rgb(0 0 0 / 0.26), 0 -1px 0 0 rgb(0 0 0 / 0.16);
  padding: 15px;
  display: none;
}

@media (max-width: 560px) {
  #mobile-bookmark-prompt {
    left: 0;
  }
}

#mobile-bookmark-prompt span {
  display: block;
  font-size: 16px;
  padding: 0 10px 8px;
  text-align: center;
}

#mobile-bookmark-prompt button {
  width: 47%;
  margin-top: 10px;
}

#mobile-bookmark-prompt button.btn-primary {
  float: right;
  font-weight: 500;
}

.mobile-prompt-modal {
  text-align: center;
}

.mobile-prompt-modal ol {
  -webkit-padding-start: 0;
  padding: 0 7px 0 22px;
}

.mobile-prompt-modal ol li {
  font-size: 16px;
  margin-top: 10px;
  text-align: left;
}

.mobile-prompt-modal ol li em {
  font-style: normal;
  color: #8BBE45;
}

.mobile-prompt-modal ol img {
  display: inline-block;
  margin: 8px 0 24px -12px;
  width: 95%;
}

.mobile-prompt-modal button.dismiss {
  display: block;
  margin: 0 auto;
  width: 120px;
}

div.popup-tab {
  position: fixed;
  bottom: -100px;
  right: 48px;
  transition: bottom;
  transition-duration: 0.4s;
  z-index: 1053;
}

div.popup-tab.show {
  bottom: 0;
}

div.popup-tab div {
  background-color: #5DA50A;
  border-radius: 2px;
  border: solid;
  border-width: 1px;
  border-color: green;
  color: white;
  font-family: "Public Sans", Helvetica, Arial, sans-serif;
  font-size: 18px;
  padding: 8px 16px;
  text-align: center;
}

.no-user-select {
  -webkit-touch-callout: none;
  user-select: none;
  cursor: default;
}

.btn.disabled {
  pointer-events: none;
  cursor: pointer;
  outline: none;
}

.btn.with-spinner,
.btn.with-spinner:hover,
.btn.with-spinner:focus,
.btn.with-spinner:active {
  transition-property: background-image, padding-left;
  transition-duration: 0.2s;
  background-image: url("https://static.bureau.getbite.com/images/ajax-loader-444.gif");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 5px 50%;
  padding-left: 30px;
}

input[type="search"]::-webkit-search-cancel-button {
  appearance: searchfield-cancel-button;
}

.input-group .input-group-btn {
  margin-left: 12px;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.kiosk-map-info-window .badge-img {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

@keyframes flash {
  0%,
  50%,
  100% {
    background-color: orange;
  }

  25%,
  75% {
    background-color: inherit;
  }
}

@keyframes flash {
  0%,
  50%,
  100% {
    background-color: orange;
  }

  25%,
  75% {
    background-color: inherit;
  }
}

.flash {
  animation-name: flash;
}

/* BUTTON STYLES */
.btn {
  --bs-btn-font-family: var(--font-primary);
  --bs-btn-color: white;
  --bs-btn-font-weight: bold;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 0.3), 0 -2px 2px 0 rgb(0 0 0 / 0.1) inset, 0 -12px 15px 0 rgb(255 255 255 / 0.12) inset, 0 12px 20px 0 rgb(0 0 0 / 0.04) inset;
  --bs-btn-disabled-opacity: 1;
}

.btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-font-weight: bold;
  --bs-btn-bg: #0D2481;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #102C9C;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #112FA8;
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: inset 0 3px 5px rgb(13 36 129 / 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #BCC1CC;
  --bs-btn-disabled-border-color: #BCC1CC;

  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 0.3), 0 -2px 2px 0 rgb(0 0 0 / 0.1) inset, 0 -12px 15px 0 rgb(255 255 255 / 0.12) inset, 0 12px 20px 0 rgb(0 0 0 / 0.04) inset;
}

.btn-secondary {
  --bs-btn-color: black;
  --bs-btn-font-weight: 400;
  --bs-btn-bg: white;
  --bs-btn-border-color: #999999;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #E6E6E6;
  --bs-btn-hover-border-color: #808080;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgb(0 0 0 / 0.125);
  --bs-btn-disabled-color: #BCC1CC;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #999999;

  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 0.3), 0 -2px 2px 0 rgb(0 0 0 / 0.1) inset, 0 -12px 15px 0 rgb(255 255 255 / 0.12) inset, 0 12px 20px 0 rgb(0 0 0 / 0.04) inset;
}

.btn-success {
  --bs-btn-color: #ffffff;
  --bs-btn-font-weight: bold;
  --bs-btn-bg: #27AA5E;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #219150;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1E8549;
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: inset 0 3px 5px rgb(30 133 73 / 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #BCC1CC;
  --bs-btn-disabled-border-color: #BCC1CC;

  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 0.3), 0 -2px 2px 0 rgb(0 0 0 / 0.1) inset, 0 -12px 15px 0 rgb(255 255 255 / 0.12) inset, 0 12px 20px 0 rgb(0 0 0 / 0.04) inset;
}

.btn-danger {
  --bs-btn-color: #ffffff;
  --bs-btn-font-weight: bold;
  --bs-btn-bg: #E74C3C;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #CF4536;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #C24132;
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: inset 0 3px 5px rgb(194 65 50 / 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #BCC1CC;
  --bs-btn-disabled-border-color: #BCC1CC;

  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 0.3), 0 -2px 2px 0 rgb(0 0 0 / 0.1) inset, 0 -12px 15px 0 rgb(255 255 255 / 0.12) inset, 0 12px 20px 0 rgb(0 0 0 / 0.04) inset;
}

.btn-warning {
  --bs-btn-color: black;
  --bs-btn-font-weight: bold;
  --bs-btn-bg: #FBCC6C;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #E0B55E;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #D4AB59;
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: inset 0 3px 5px rgb(251 204 108 / 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #BCC1CC;
  --bs-btn-disabled-border-color: #BCC1CC;

  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 0.3), 0 -2px 2px 0 rgb(0 0 0 / 0.1) inset, 0 -12px 15px 0 rgb(255 255 255 / 0.12) inset, 0 12px 20px 0 rgb(0 0 0 / 0.04) inset;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.4285;
  color: #333333;
  word-break: break-word;
  word-wrap: break-word;
  background-color: #F5F5F5;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
}

/* System health table */
.system-health-view .table tr.success {
  --bs-table-bg: #D9EAD3;
}

.system-health-view .table tr.warning {
  --bs-table-bg: #FFF2CC;
}

.system-health-view .table tr.danger {
  --bs-table-bg: #F4CCCC;
}

/* Search Tool */
.bite-search-view {
  position: absolute;
  width: 100%;
  height: 100%;
  background:rgb(0 0 0 / 0.6);
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bite-search-view .search-field,
.bite-search-view .search-results {
  width: 60%;
  max-width: 700px;
}

.bite-search-view .search-field {
  background: rgb(0 0 0 / 0.6);
  padding: 12px;
  border-radius: 6px;
}

.bite-search-view .search-field .form-control {
  --bs-body-bg: rgb(0 0 0 / 0.5);
  --bs-body-color: white;
}

.bite-search-view .search-field .form-control:focus {
  border-color: white;
  box-shadow: none;
}

.bite-search-view .search-field .form-control::placeholder {
  color: white;
}

.bite-search-view .search-field input[type="search"]::-webkit-search-cancel-button {
  filter: grayscale(1) invert(100%);
}

.bite-search-view .search-field .helper-text {
  color: white;
  margin-top: 8px;
}

.bite-search-view .search-results {
  margin-top: 8px;
  --bs-list-group-bg: rgb(0 0 0 / 0.6);
  --bs-list-group-border-color: transparent;
}

.bite-search-view .search-results .result,
.bite-search-view .search-results .match {
  padding: 6px;
}

.bite-search-view .search-results .list-group-item.clickable:hover {
  background-color: rgb(0 0 0 / 0.5);
}

.bite-search-view .search-results.list-group .list-group-item .cell-row {
  display: block;
}

.bite-search-view .search-results a,
.bite-search-view .search-results .match {
  color: white;
  text-decoration: none;
  display: block;
}

