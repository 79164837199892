.report .item {
  border: solid 1px lightgrey;
  border-radius: 4px;
  display: inline-block;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 0.16);
  margin: 4px 8px 4px 0;
}

.report .item .item-container {
  display: flex;
}

.report .item .image {
  background-color: #EEEEEE;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 48px;
  width: 48px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.report .item .name {
  vertical-align: middle;
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
}

.report .summary {
  font-size: 14px;
  margin-bottom: 16px;
}

.report .summary .header {
  border-bottom: solid 1px #CCCCCC;
  color: #999999;
  font-size: 12px;
}
