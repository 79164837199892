@import "https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,400;0,600;0,700;0,900;1,400;1,600&display=swap";
@import "https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;600&display=swap";

:root {
  --font-primary: "Public Sans", helvetica, arial, sans-serif;
  --font-monospace: "Fira Code", menlo, monospace;
}

.field-group {
  padding: 10px;
}

@media (max-width: 560px) {
  .field-group {
    padding: 0;
  }

  .field-group .field-container .col-md-1,
  .field-group .field-container .col-md-2,
  .field-group .field-container .col-md-3,
  .field-group .field-container .col-md-4,
  .field-group .field-container .col-md-5,
  .field-group .field-container .col-md-6,
  .field-group .field-container .col-md-7,
  .field-group .field-container .col-md-8,
  .field-group .field-container .col-md-9,
  .field-group .field-container .col-md-10,
  .field-group .field-container .col-md-11,
  .field-group .field-container .col-md-12,
  .field-group + .item-panel.col-md-12 {
    padding: 0;
  }

  .field-group .field-container .panel-body {
    padding: 10px;
  }
}

.field-group .field-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.modal-body .field-group .field-container {
  flex-wrap: wrap;
}

.field-group .field-container.has-error {
  color: red;
}

.field-group .field-container::after {
  content: "";
  clear: both;
  zoom: 1;
  height: 0;
  visibility: hidden;
  display: block;
}

.field-group .field-container.header-item {
  border-bottom: 1px solid rgb(221 221 221);
  padding-bottom: 25px;

  /* Hack to get the field to extend its border past the container's padding. */
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
}

.field-group .field-container.nested-object > .input-container {
  text-align: right;
  padding-bottom: 10px;
}

.field-group .field-container.nested-object > .field-group {
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  width: 100%;
}

.field-group .field-container label {
  padding-top: 8px;
  padding-right: 0;
  font-weight: bold;
}

.field-group .field-container .col-form-label {
  font-weight: bold;
}

.field-group .field-container + .field-container {
  margin-top: 10px;
}

@media (max-width: 560px) {
  .page-container .field-group .field-container label.col-form-label {
    padding-left: 15px;
  }

  .page-container .field-group .field-container .input-container {
    padding: 15px;
  }
}

.field-group .field-container .input-container textarea {
  height: 100px;
}

@media (max-width: 1024px) {
  .field-group .field-container .input-container textarea,
  .field-group .field-container .input-container input {
    font-size: 16px;
    line-height: 1.5;
  }
}

input[type="checkbox"],
input[type="radio"] {
  width: 20px;
  margin-top: 2px;
}

.field-group .field-container .input-container textarea.no-content {
  font-style: italic;
  height: 34px;
}

@media (max-width: 1024px) {
  .field-group .field-container .input-container textarea.no-content {
    height: 38px;
  }
}

.field-group .field-container .input-container .clear-button,
.field-group .field-container .input-container .toggle-input-mask-button {
  background-color: transparent;
  border: 1px solid #999999;
  border-radius: 6px;
  color: black;
  height: 38px;
  margin-left: 12px;
}

.field-group .field-container .input-container .clear-button span.glyphicon,
.field-group .field-container .input-container .toggle-input-mask-button span.glyphicon {
  padding: 0;
}

.field-group .field-container .expand-button {
  background-color: #DDDDDD;
  border-color: #BBBBBB;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: white;
  display: table-cell;
  font-weight: 500;
  font-size: 2rem;
  height: 36px;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  width: 36px;
}

.field-group .field-container .expand-button:active {
  background-color: #CCCCCC;
}

.field-group .field-container .input-container .item-dropdown,
.field-group .field-container .input-container .item-dropdown > .dropdown-toggle,
.field-group .field-container.list-field-view .card .select-panel > .btn-group > .item-dropdown,
.field-group .field-container.list-field-view .card .select-panel > .btn-group > .item-dropdown > .dropdown-toggle,
.field-group + .item-panel .card .select-panel > .btn-group > .item-dropdown,
.field-group + .item-panel .card .select-panel > .btn-group > .item-dropdown > .dropdown-toggle,
.page-container > .control-pane.section > .new-panel > .btn-group > .item-dropdown,
.page-container > .control-pane.section > .new-panel > .btn-group > .item-dropdown > .dropdown-toggle {
  width: 100%;
  box-shadow: none;
}

.field-group .field-container .input-container.has-clear-button .form-control,
.field-group .field-container .input-container.has-toggle-input-mask-button .form-control,
.field-group .field-container .input-container.has-clear-button .item-dropdown {
  /* This width is referenced in .field-group .field-container .input-container .clear-button */
  width: calc(100% - 54px);
  display: inline-block;
  vertical-align: top;
}

.field-group .field-container .input-container.has-clear-button {
  display: flex;
}

.field-group .field-container .input-container .code-string {
  height: 100px;
  font-family: var(--font-monospace);
}

.field-group .field-container .input-container input[type="checkbox"] {
  box-shadow: none;
  height: 24px;
}

.field-group .field-container + .field-container .form-check {
  border: none;
}

.field-group .field-container .input-container .form-control-feedback {
  right: 15px;
  display: none;
}

.field-group .field-container .input-container .form-control-feedback.fetching {
  background-image: url("https://static.bureau.getbite.com/images/spinner-green-on-white.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 65%;
}

.field-group .field-container.has-success .input-container .form-control-feedback.glyphicon-ok,
.field-group .field-container.fetching .input-container .form-control-feedback.fetching {
  display: block;
}

.field-group .field-container .input-container .form-text {
  display: none;
}

.field-group .field-container.has-error .input-container .form-text,
.field-group .field-container.has-disclaimer .input-container .form-text {
  display: block;
}

.field-group .field-container .image-previews img {
  margin: 10px 5px 0;
  width: 100px;
  height: 100px;
}

.field-group .field-container .autocomplete-list {
  position: relative;
}

.field-group .field-container .autocomplete-list ul.dropdown-menu > li.selected > a,
.field-group .field-container .autocomplete-list ul.dropdown-menu > li.selected > a:hover,
.field-group .field-container .autocomplete-list ul.dropdown-menu > li.selected > a:focus {
  background-color: #CCCCCC;
}

.field-group .field-container .accordion {
  margin-top: 15px;
}

.field-group .field-container .accordion > .card > .list-group > .complex-cell > .field-group {
  border-bottom: 1px solid #DDDDDD;
  padding: 16px;
}

.field-group .field-container.alert-rules-panel .accordion > .card > .list-container.list-group,
.field-group .field-container.links-panel .accordion > .card > .list-container.list-group,
.field-group .field-container.price-option-panel .accordion > .card > .list-container.list-group,
.field-group .field-container.tax-rates-panel .accordion > .card > .list-container.list-group {
  overflow-y: initial;
}

@media (max-width: 560px) {
  .field-group .field-container.alert-rules-panel .field-group,
  .field-group .field-container.links-rules-panel .field-group,
  .field-group .field-container.price-option-panel .field-group,
  .field-group .field-container.tax-rates-panel .field-group {
    padding: 0 10px 10px;
  }
}

.field-group .field-container .warning {
  display: block;
  position: relative;
  min-height: 1px;
  color: red;
  margin: 0 15px 15px;
}

.field-group .field-container.list-field-view {
  width: 100%;
}

.field-group .field-container.list-field-view .card {
  margin-bottom: 0;
}

.field-group .field-container.list-field-view .card div.warning {
  margin: 0 15px 15px;
}

.field-group .field-container.list-field-view .card div.warning span.warning,
.field-group .field-container.list-field-view .card div.empty-list-warning span.warning {
  color: red;
}

.field-group .field-container.list-field-view .card .select-panel,
.field-group + .item-panel .card .select-panel,
.page-container > .control-pane.section > .new-panel {
  width: 100%;
}

.field-group .field-container.list-field-view .card .select-panel > .btn-group,
.field-group + .item-panel .card .select-panel > .btn-group,
.page-container > .control-pane.section > .new-panel > .btn-group {
  display: table;
  width: 100%;
}

@media (max-width: 560px) {
  .field-group .field-container.list-field-view .card .select-panel > .btn-group > .item-dropdown,
  .field-group + .item-panel .card .select-panel > .btn-group > .item-dropdown,
  .page-container > .control-pane.section > .new-panel > .btn-group > .item-dropdown {
    display: table-cell;
    float: none;
  }
}

.field-group .field-container.list-field-view .card .select-panel > .btn-group > .btn.add,
.field-group + .item-panel .card .select-panel > .btn-group > .btn.add,
.page-container > .control-pane.section > .new-panel > .btn-group > .btn.add {
  display: table-cell;
}

/* Give it a min height in order to help the modal height calculation be correct */
.field-group .field-container.images-field-view .images-container {
  min-height: 140px;
}

.field-group .field-container.images-field-view .images-container .plus-button {
  float: left;
  width: 40px;
  height: 40px;
  background-color: #DDDDDD;
  border: 1px solid #BBBBBB;
  border-radius: 4px;
  color: white;
  line-height: 40px;
  margin-top: 40px;
  cursor: pointer;
  text-align: center;
  font-size: 3em;
  font-weight: 600;
}

.field-group .field-container.images-field-view .images-container .plus-button:hover {
  color: #98CC52;
}

.field-group .field-container.images-field-view .image-upload-view {
  float: left;
  margin-right: 20px;
  margin-top: 10px;
  position: relative;
}

.field-group .field-container.images-field-view .image-upload-view.has-frame {
  width: 355px;
}

.field-group .field-container.images-field-view .image-upload-view .scrim {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  z-index: 100;
  background-color: rgb(0 0 0 / 0.7);
}

.field-group .field-container.images-field-view .image-upload-view .scrim > div {
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  font-size: 1.35em;
  line-height: 120px;
}

.field-group .field-container.images-field-view .image-upload-view .scrim .spinner {
  background-image: url("https://static.bureau.getbite.com/images/spinner-green-on-white.gif");
  background-repeat: no-repeat;
  background-position: center;
}

.field-group .field-container.images-field-view .image-upload-view .scrim .retry {
  cursor: pointer;
  padding-top: 15px;
  line-height: 1em;
}

.field-group .field-container.images-field-view .image-upload-view .scrim .retry .msg {
  font-size: 0.8em;
  display: block;
  margin-top: 12px;
}

.field-group .field-container.images-field-view .image-upload-view .scrim .retry:hover {
  color: #98CC52;
}

.field-group .field-container.images-field-view .image-upload-view .scrim.confirmation {
  background-color: black;
  text-align: center;
}

.field-group .field-container.images-field-view .image-upload-view .scrim.confirmation span {
  color: white;
  display: block;
  font-size: 13px;
  line-height: 1.25em;
  margin: 13px 2px;
}

.field-group .field-container.images-field-view .image-upload-view .scrim.confirmation .decline {
  margin-right: 10px;
}

.field-group .field-container.images-field-view .image-upload-view .delete-button {
  cursor: pointer;
  position: absolute;
  width: 28px;
  height: 28px;
  top: -13px;
  left: 106px;
  background: url("https://static.bureau.getbite.com/images/icon-remove.png") no-repeat center;
  background-size: cover;
  z-index: 2;
}

.field-group .field-container.images-field-view .image-upload-view .delete-button:hover {
  background: url("https://static.bureau.getbite.com/images/icon-remove-hover.png") no-repeat center;
  background-size: cover;
}

.field-group .field-container.images-field-view .image-upload-view .image-preview {
  width: 120px;
  height: 120px;
  border: 1px solid #DDDDDD;
}

.field-group .field-container.images-field-view .image-upload-view .image-preview.loading {
  background-image: url("https://static.bureau.getbite.com/images/spinner-green-on-white.gif");
  background-repeat: no-repeat;
  background-position: center;
}

.field-group .field-container.images-field-view .image-upload-view .input-container {
  height: 30px;
}

.field-group .field-container.images-field-view .image-upload-view .drop-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  text-align: center;
  padding: 41px 10px;
  font-size: 1.1em;
  box-shadow: inset 0 0 10px #DDDDDD;
  cursor: default;
}

.field-group .field-container.images-field-view .image-upload-view .drop-area.drag-over {
  box-shadow: inset 0 0 10px #98CC52;
  color: #98CC52;
}

.field-group .field-container.images-field-view .image-upload-view button.file-input {
  width: 120px;
  font-size: 14px;
  padding-right: 0;
  padding-left: 0;
}

.field-group .field-container.images-field-view .image-upload-view .error {
  position: absolute;
  width: 120px;
  height: 120px;
  top: 0;
  left: 0;
  background-color: black;
  color: red;
  text-align: center;
  padding: 8px 4px;
}

.field-group .field-container.map-field-view .wrapper {
  border: 1px solid #DDDDDD;
  margin-top: 10px;
}

.field-group .field-container.map-field-view.custom-strings .wrapper .map-field-row-view {
  flex-direction: column;
}

.field-group .field-container.map-field-view.custom-strings .wrapper .map-field-row-view .field-container:last-child {
  padding-bottom: 8px;
}

.field-group .field-container.map-field-view.custom-strings .wrapper .map-field-row-view .btn-group button {
  float: right;
  margin: 12px 0;
}

.field-group .field-container.map-field-view .map-field-row-view {
  display: flex;
  width: 100%;
}

.field-group .field-container.map-field-view .map-field-row-view > label.name {
  vertical-align: top;
  width: 127px; /* hack to force this to take up max space */
}

.field-group .field-container.map-field-view .map-field-row-view > .field-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 16px;
  margin-right: 12px;
}

.field-group .field-container.map-field-view .map-field-row-view > .field-container .col-md-4 {
  width: initial;
}

.field-group .field-container .select-panel.input-group.card-body {
  margin: 12px;
  width: calc(100% - 24px);
}

.field-container .col-md-8 {
  width: calc(66.66% - 16px);
  margin-left: 16px;
}

.field-group .field-container.map-field-view .map-field-row-view > .btn-group {
  vertical-align: top;
  height: 100%;
}

.field-group .field-container.timestamp-field-view .form-control.date,
.field-group .field-container.timestamp-field-view .form-control.time {
  float: left;
  text-align: center;
}

.field-group .field-container.timestamp-field-view .form-control.date {
  width: 120px;
}

.field-group .field-container.timestamp-field-view .form-control.time {
  width: 90px;
  margin-right: 12px;
}

.field-tooltip {
  background-color: lightgray;
  border-radius: 50%;
  color: white;
  font-size: 16px;
  height: 20px;
  text-align: center;
  width: 20px;
  line-height: 20px;
  display: inline-block;
  margin-left: 5px;
}

.field-group .field-tooltip {
  float: right;
}

.field-tooltip::after {
  content: "?";
}

.field-tooltip.has-warning {
  background-color: #A94442;
}

.field-tooltip.has-warning::after {
  content: "!";
}

.field-tooltip.override-state-tooltip {
  cursor: pointer;
  background-color: #27AA5E;
}

.field-tooltip.override-state-tooltip::after {
  content: "=";
}

.field-tooltip.override-state-tooltip.overriden {
  background-color: #FBCC6C;
  color: black;
}

.field-tooltip.override-state-tooltip.overriden::after {
  content: "≠";
}

@media (max-width: 560px) {
  .field-tooltip {
    margin-left: 4px;
  }
}

.field-tooltip:hover,
.field-tooltip:active {
  background-color: gray;
}

.field-tooltip.has-warning:hover,
.field-tooltip.has-warning:active {
  background-color: red;
}

.field-container.time-range-field-view.condensed > .col-form-label {
  width: 20%;
}

.field-container.time-range-field-view.condensed > .input-container {
  width: 80%;
  padding-right: 0;
  padding-left: 0;
}

.field-container.day-timestamp-field-view input.time,
.field-container.time-range-field-view input.time,
.field-container.time-range-field-view input.date {
  width: 100px;
  display: inline-block;
}

.field-container.time-range-field-view input.time.from,
.field-container.time-range-field-view input.date.from {
  margin-right: 20px;
}

.field-container.time-range-field-view span.time-label {
  margin-right: 5px;
}

.field-container.array-field-view > div.col-md-12 > div.card {
  margin-bottom: 0;
}

.field-container.extended .input-container {
  width: 100%;
}

.field-container .check-list .check-item,
.field-container .radio-list .radio-item {
  border-radius: 8px;
  border: 1px solid #E6E6E6;
  font-size: 12px;
  color: #666666;
  font-weight: 600;
  margin-top: 4px;
  padding: 8px;
  width: calc(50% - 4px);
  background-color: transparent;
  cursor: pointer;
}

.field-container .check-list .check-item.disabled,
.field-container .radio-list .radio-item.disabled {
  cursor: not-allowed;
}

.field-container.extended .check-list .check-item {
  display: block;
  background-color: white;
  border-radius: 0;
  border: 1px solid #EEEEEE;
  border-bottom: 0;
  margin: 0;
}

.field-container.extended .check-list .check-item:last-child {
  border: 1px solid #EEEEEE;
}

.field-container .radio-list .radio-item:active {
  background-color: #CCCCCC;
}

.field-container .radio-list .radio-item.disabled:active {
  background-color: #EEEEEE;
}

.field-container .check-list .check-item input,
.field-container .radio-list .radio-item input {
  margin-right: 8px;
  cursor: pointer;
}

.field-container .check-list .check-item.disabled input,
.field-container .radio-list .radio-item.disabled input {
  cursor: not-allowed;
}

.field-container .check-list .check-item .badge-img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.field-container.extended .check-list .check-item .disclaimer {
  display: block;
  font-weight: normal;
  padding-left: 28px;
}

.field-container.visible-in .input-container {
  display: table;
  padding-top: 7px;
  padding-bottom: 7px;
}

.field-container.visible-in .cell-body {
  width: 100%;
  display: table-cell;
  vertical-align: middle;
}

.field-container.visible-in .cell-body.item-hidden {
  color: grey;
}

.field-container.visible-in .btn-group-container {
  display: table-cell;
  padding-left: 8px;
  padding-bottom: 0;
  vertical-align: middle;
}

.field-container.visible-in .btn-group-container .btn-group {
  display: flex;
  float: right;
}

.field-container.visible-in .btn-group-container .btn-group.collapsed {
  display: none;
}

@media (max-width: 560px) {
  .field-container.visible-in .btn-group-container .btn-group.collapsed {
    display: initial;
  }

  .field-container.visible-in .btn-group-container .btn-group.collapsed + .btn-group {
    display: none;
  }
}

.field-container div span.simple-value {
  display: block;
  padding-top: 8px;
}

.item-list-panel.main-panel {
  margin-top: 16px;
}

/* Bootstrap tooltip. */
.tooltip-inner {
  min-width: 160px;
  max-width: 360px;
  font-size: 16px;
}

.warning-panel {
  margin: 20px 0;
  padding: 12px;
  white-space: pre-wrap;
  color: red
}
