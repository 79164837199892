.list-group a.list-group-item,
.list-group .list-group-item.clickable {
  cursor: pointer;
}

/* Bootstrap icon adjustments */
.list-group .list-group-item.clickable .bi.bi-list,
.list-group .list-group-item .bi.bi-list.handle {
  color: #999999;
  display: table-cell;
  font-size: 1.5rem;
  padding: 12px;
  vertical-align: middle;
}

.list-group .list-group-item.clickable .bi.bi-trash3 {
  color: black;
  margin-right: 6px;
}

.list-group .list-group-item.clickable:hover,
.list-group .list-group-item.clickable:focus {
  background-color: #F5F5F5;
}

.list-group .list-group-item .cell-row {
  display: table-row;
}

.list-group .list-group-item .btn-group {
  display: flex;
}

.list-group .list-group-item .btn-group:empty {
  display: none;
}

.list-group .list-group-item .btn-group-container .btn-group {
  display: flex;
  padding: 10px 10px 10px 0;
}

button span.glyphicon {
  padding-right: 3px;
}

button span.glyphicon-cog {
  vertical-align: middle;
  font-size: 20px;
}

.list-group .list-group-item span.handle {
  color: lightgrey;
  cursor: move;
  display: table-cell;
  vertical-align: middle;
  padding: 15px;
  margin-right: -10px;
}

@media (max-width: 768px) {
  .list-group .list-group-item span.handle {
    padding: 22px 15px;
  }
}

.list-group .list-group-item .cell-body {
  width: 100%;
  display: table-cell; /* previously inline */
  vertical-align: middle;
}

/* padding in case there's no handle */
.list-group .list-group-item .cell-body:first-child {
  padding-left: 15px;
}

.list-group .list-group-item.item86d .cell-body {
  color: grey;
}

.list-group .list-group-item .cell-body .note {
  font-size: 12px;
  margin-top: 6px;
}

.list-group .list-group-item.item86d .cell-body .note {
  display: block;
  margin: -5px 10px 10px 0;
}

.list-group .list-group-item .cell-body .name .auto-reco {
  color: #0099DD;
  border: 1px solid #0099DD;
  padding: 3px 6px;
  text-transform: uppercase;
}

.list-group .list-group-item .btn-group-container {
  display: table-cell;
  padding-right: 8px;
  padding-bottom: 0;
  vertical-align: middle;
}

@media (max-width: 560px) {
  .list-group .list-group-item span.handle {
    display: table-cell;
  }

  .list-group .list-group-item .cell-body {
    display: table-cell;
    padding: 4px 0;
  }

  .list-group .list-group-item .btn-group-container {
    display: table-cell;
    float: none;
    padding: 4px 0;
  }

  .list-group .list-group-item .btn-group-container .btn {
    white-space: normal;
  }

  .list-group .list-group-item .btn-group .btn {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

.list-group .list-group-item .btn-group-container .btn-group.collapsed {
  display: none;
}

@media (max-width: 560px) {
  .list-group .list-group-item .btn-group-container .btn-group.collapsed {
    display: initial;
  }

  .list-group .list-group-item .btn-group-container .btn-group.collapsed + .btn-group {
    display: none;
  }
}

.list-group .list-group-item .btn-group-container .btn-group .btn-sm {
  line-height: 1.4;
  display: inline-flex;
  align-items: center;
}

@media (max-width: 768px) {
  .list-group .list-group-item .btn-group-container .btn-group .btn-sm {
    min-height: 48px;
  }
}

.list-group .list-group-item.ghost,
.list-group .complex-cell.ghost .list-group-item {
  opacity: 0.5;
  background-color: #98CC52;
}

.list-group .list-group-item.ghost .btn-group-container {
  display: none;
}

.list-group .hidden-item-divider {
  text-align: center;
  padding: 15px 20px;
  color: gray;
}

.btn.dropdown-toggle {
  text-align: left;
  width: 280px;
}

.base-list-view .card-header .right-button-container .select-panel.input-group {
  display: initial;
}

.base-list-view .card-header .right-button-container .select-panel .btn.dropdown-toggle {
  width: auto;
  display: inline-flex;
  align-items: center;
}

.base-list-view .card-header .right-button-container .select-panel .btn.dropdown-toggle .text {
  width: 100%;
}

.base-list-view .card-header .right-button-container .select-panel .btn.dropdown-toggle::after {
  margin-left: -7px;
  vertical-align: text-top;
}

.accordion-panel .card-header .right-button-container .select-panel.input-group {
  display: initial;
}

.accordion-panel .card-header .right-button-container .select-panel .btn.dropdown-toggle {
  width: auto;
  display: inline-flex;
  align-items: center;
}

.btn.dropdown-toggle .text {
  margin-right: 15px;
  overflow-x: hidden;
  overflow-y: hidden;
  width: calc(100% - 30px);
  display: inline-flex;
}

.open > .dropdown-menu {
  overflow-x: hidden;
  width: 100%;
  max-height: 185px;  /* 5.5 rows + search box */
  display: block;
}

.open > .dropdown-menu-right {
  right: 0;
}

@media (max-width: 1024px) {
  .open > .dropdown-menu {
    max-height: 205px;  /* 5.5 choices + search box */
  }
}

.open > .dropdown-menu.dropdown-menu-right {
  left: initial;
  min-width: 220px;
}

.open > .dropdown-menu.dropdown-menu-right.kiosk-actions {
  min-width: 230px;
}

.open > .dropdown-menu li.search {
  padding: 3px 20px;
}

.open > .dropdown-menu li.search input {
  width: 100%;
  font-weight: 400;
  line-height: 1.4285;
  color: #333333;
  white-space: nowrap;
  padding: 1px 3px;
}

.open > .dropdown-menu li.search button.select {
  width: 100%;
  margin-top: 7px;
}

.open > .dropdown-menu li a {
  cursor: pointer;
  display: block;
  padding: 1px 10px;
  clear: both;
  font-weight: 400;
  line-height: 1.4285;
  color: #333333;
  text-decoration: none;
}

.open > .dropdown-menu li a.selected {
  background-color: #0099DD;
}

.open > .dropdown-menu li a span.subtitle {
  color: #999999;
  display: block;
  font-size: 10px;
}

@media (max-width: 768px) {
  .open > .dropdown-menu li a {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.item-dropdown.open > .dropdown-menu li a span.warning,
.btn.dropdown-toggle .text span.warning,
.list-group .list-group-item span.warning {
  color: red;
  min-width: 150px;
}

.open > .dropdown-menu li a img.dropdown-img {
  max-width: 80px;
  margin-right: 10px;
  float: left;
}
