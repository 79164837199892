.bite-log-cell {
  padding: 8px;
}

.bite-log-cell .cell-row > div {
  display: table-cell;
  padding-right: 8px;
  vertical-align: middle;
  white-space: nowrap;
}

.bite-log-cell .cell-row .timestamp {
  font-size: 14px;
}

.bite-log-cell .cell-row .device-name {
  font-weight: 500;
}

.bite-log-cell .cell-row .error-type {
  background-color: darkgrey;
  border-radius: 4px;
  color: white;
  font-size: 12px;
  font-weight: 500;
  padding: 4px;
  text-transform: uppercase;
}

.bite-log-cell .cell-row .status {
  padding-left: 8px;
  width: 100%;
}

.bite-log-cell.selected .cell-row .timestamp,
.bite-log-cell.selected .cell-row .device-name,
.bite-log-cell.selected .cell-row .status {
  color: #FF7401;
}

.bite-log-cell.error .cell-row .error-type {
  background-color: red;
}

.bite-log-cell.kiosk-ok .cell-row .error-type {
  background-color: green;
}

.bite-log-cell.warning .cell-row .error-type {
  background-color: orange;
}

.bite-log-view img.screenshot {
  display: block;
  max-width: 100%;
}
