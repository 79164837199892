.cell-badge.opted-out {
  background-color: #5F166C;
}

.guest-view .header {
  display: flex;
}

.guest-view .header .image {
  background-color: white;
  background-image: url("https://static.bureau.getbite.com/images/female-user@2x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 100px;
  height: 100px;
  margin-bottom: 8px;
  width: 100px;
}

.guest-view .header .image.male {
  background-image: url("https://static.bureau.getbite.com/images/male-user@2x.png");
}

.guest-view .header .info {
  flex-grow: 3;
  margin-left: 8px;
  padding: 0 8px 8px;
}

.guest-view .header .info .title {
  color: #555555;
  font-size: 24px;
  font-weight: 500;
}

.guest-view .controls {
  margin-bottom: 16px;
}

.guest-view .controls button {
  margin-right: 5px;
}

.guest-view .order-history .title {
  font-weight: 500;
}

.guest-view .order-history .order-list {
  font-size: 14px;
}

.guest-view .order-history .order-list .order {
  border: solid 1px lightgrey;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 0.16);
  margin: 8px 0;
  padding: 8px;
}

.guest-view .order-history .order-list .order .ordered-item-list {
  margin: 16px 0 8px;
}
