
@import "https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,400;0,600;0,700;0,900;1,400;1,600&display=swap";

:root {
  --font-primary: "Public Sans", helvetica, arial, sans-serif;
}

#sidebar,
#sidebar-scrim {
  position: fixed;
  top: 58px;
  left: 0;
  bottom: 0;
  z-index: 3;
}

#sidebar {
  background-color: rgb(0 0 0 / 0);
  width: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0) translateZ(0);
  perspective: 1000;
}

#sidebar-border {
  position: fixed;
  top: 0;
  left: calc(170px - 1px);
  bottom: 0;
  z-index: 2;
  width: 1px;
  background-color: #B7B7B7;
}

#sidebar .contents {
  position: relative;
  min-height: 100%;
  transform: translateZ(0);
  padding-top: 20px;
  border-right: 1px solid #CCCCCC;
  background-color: #F5F5F5;
}

#sidebar .contents .sb-el {
  color: #666666;
  line-height: 1.3;
  text-align: right;
  padding: 4px 14px 4px 4px;
  margin: 0 6px;
  font-size: 16px;
  font-family: var(--font-primary);
  transform: translateZ(0);
}

#sidebar .contents .sb-el.tab:not(.selected) {
  cursor: pointer;

  /* transparent border to prevent jitter when a selected element gets a
     coloured border */
  border: 1px solid rgb(0 0 0 / 0);
  -webkit-tap-highlight-color: rgb(0 0 0 / 0.3);
}

#sidebar .contents .sb-el.link {
  cursor: pointer;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0.3);
}

#sidebar .contents .sb-el.selected {
  background-color: #F58538;
  border-radius: 4px;
  color: white;
  font-weight: bold;
}

/*          #sidebar .contents .sb-el.tab:not(.selected):active, */
#sidebar .contents .sb-el.tab:not(.selected):hover,
/*          #sidebar .contents .sb-el.link:active,*/
#sidebar .contents .sb-el.link:hover {
  color: #FF7401;
  background-color: rgba(0 0 0 / 0.05);
  border-radius: 4px;
}

#sidebar .contents .sb-el img.icon-hover {
  display: none;
}

#sidebar .contents .sb-el.selected img.icon,
/*          #sidebar div.tab:not(.selected):active img.icon,*/
#sidebar .contents .sb-el.tab:not(.selected):hover img.icon,
/*          #sidebar div.link:active img.icon,*/
#sidebar .contents .sb-el.link:hover img.icon {
  display: none;
}

#sidebar .contents .sb-el.selected img.icon-hover,
/*          #sidebar div.tab:not(.selected):active img.icon-hover,*/
#sidebar .contents .sb-el.tab:not(.selected):hover img.icon-hover,
/*          #sidebar div.link:active img.icon-hover,*/
#sidebar .contents .sb-el.link:hover img.icon-hover {
  display: inline;
}

#sidebar .contents .sb-el.tab.subtab span::before {
  content: "\21B3";
  margin-right: 3px;
}

#sidebar .contents .sb-el.spacer {
  height: 24px;
}

#sidebar .contents .sb-el.separator {
  border-top: 4px dotted rgba(0 0 0 / 0.2);
  color: rgba(0 0 0 / 0.2);
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 3px;
  text-transform: uppercase;
}

#sidebar .contents .sb-el.link img {
  vertical-align: -5px;
}

#sidebar .contents .sb-el.small-text,
#sidebar .contents .sb-el.footer {
  color: #7F7F7F;
  font-size: 12px;
}

#sidebar .contents .sb-el.small-text {
  line-height: 12px;
}

#sidebar .contents .sb-el.tab span.has-badge {
  position: relative;
}

#sidebar .contents .sb-el.tab span.has-badge[data-badge]::before {
  content: "";
  position: absolute;
  top: 2px;
  left: -20px;
  background-color: #FF7401;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

#sidebar .contents .sb-el.footer {
  position: absolute;
  bottom: 0;
  margin-top: 10px;
  padding: 0 10px;
  width: 100%;
  text-align: center;
}

#sidebar.with-footer .contents {
  /* This is the height of the footer */
  padding-bottom: 46px;
}

/* iPad specific style */
@media (max-width: 1024px) {
  #sidebar {
    width: 215px;
  }

  #sidebar-border {
    left: calc(215px - 1px);
  }

  #sidebar .contents .sb-el.tab,
  #sidebar .contents .sb-el.separator,
  #sidebar .contents .sb-el.link {
    font-size: 18px;
  }

  #sidebar .contents .sb-el.tab {
    margin: 3px 6px;
  }

  #sidebar .contents .sb-el.small-text,
  #sidebar .contents .sb-el.footer {
    font-size: 14px;
  }

  #sidebar .contents .sb-el.link img {
    vertical-align: -3px;
  }

  #sidebar .contents .sb-el.tab span.has-badge[data-badge]::before {
    top: 7px;
    left: -20px;
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 560px) {
  #sidebar-scrim {
    width: 100%;
    display: none;
    background-color: rgb(0 0 0 / 0.3);
  }

  #sidebar-border {
    display: none;
  }

  #sidebar {
    left: -100%;
    background-color: white;
    bottom: 48px;
    width: calc(100% - 48px);
    box-shadow: 3px 3px 4px 0 rgb(0 0 0 / 0.26);
    border: none;
  }

  #sidebar .contents {
    border-right: none;
  }

  #sidebar .contents .sb-el {
    text-align: left;
    padding: 0 20px;
    border: none;
    font-size: 24px;
    font-weight: 300;
  }

  #sidebar .contents .sb-el.tab {
    padding: 8px 20px;
  }

  #sidebar .contents .sb-el.tab span.has-badge[data-badge]::before {
    left: -18px;
  }
}
