.complex-cell a.cell:not(.clickable),
.complex-cell a.cell:not(.clickable):hover {
  cursor: default;
  background-color: white;
}

.complex-cell a.cell {
  display: table-row;
}

.complex-cell a.cell span.name {
  word-break: break-word;
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}

.complex-cell.has-error a.cell span.name {
  color: #A94442;
}

.complex-cell a.cell span.accessory-text {
  word-break: break-all;
  font-size: 13px;
  color: darkgray;
  margin-left: 10px;
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  text-align: right;
}

.complex-cell a.cell span.date {
  color: darkgray;
  float: right;
  font-size: 13px;
  margin-top: 2px;
  margin-left: 10px;
}

.complex-cell a.cell .btn-group {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  float: none;
  padding: 0 6px;
}

.complex-cell a.cell .btn-group .bi.bi-eye-fill,
.complex-cell a.cell .btn-group .bi.bi-eye-slash-fill {
  line-height: 1.4;
  display: inline-block;
  padding-right: 4px;
}

.complex-cell a.cell .btn-group .bi.bi-gear-fill {
  color: #999999;
  font-size: 1.5rem;
}

.complex-cell a.cell .btn-group .btn-link.edit {
  padding: 0 4px;
}

.complex-cell a.cell .btn-group .bi.bi-eye-fill-slash {
  color: black;
  font-size: 1rem;
  display: table-cell;
  padding: 15px;
  vertical-align: middle;
}

.complex-cell a.cell .btn-group button.remove {
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
}

.complex-cell a.cell .btn-group button.edit:active {
  background-color: lightgrey;
}

.complex-cell .confirm-panel {
  padding: 10px;
}

.complex-cell .edit-panel .btn-group,
.complex-cell .confirm-panel .btn-group {
  margin: 12px 8px;
}

.complex-cell .confirm-panel .btn-group {
  display: inline-flex;
  width: auto
}

.complex-cell .edit-panel .btn-group.closer {
  margin: 6px 0;
}

.complex-cell .edit-panel .input-group.has-error input {
  border-right-width: 2px;
}

.complex-cell .confirm-panel .confirmation,
.complex-cell .error-panel .warning {
  padding-top: 5px;
  margin-right: 10px;
  display: inline-flex;
  font-style: italic;
}

@media screen and (max-width: 1024px) {
  .complex-cell .confirm-panel .confirmation,
  .complex-cell .error-panel .warning {
    width: calc(100% - 110px);
  }

  .complex-cell .edit-panel .btn-group,
  .complex-cell .confirm-panel .btn-group {
    margin: 0 8px;
  }
}

.complex-cell .label {
  color: darkgreen;
  font-weight: initial;
  font-size: 16px;
  display: table-cell;
  vertical-align: middle;
  min-width: 150px;
}

.complex-cell .warning {
  color: darkred;
  font-weight: initial;
  font-size: 12px;
  display: table-cell;
  vertical-align: middle;
}
